<template>
  <div class="view view--protocole--add">
    <form>
      <div class="view-header" v-if="pageTitle">
        <div class="view-header__header" v-if="$route.name.includes('Edit')">
          <Btn
            text="Supprimer l'essai"
            icon="trash-alt"
            @click="modalDelete = true"
            hollow
          />
        </div>
        <div class="view-header__footer">
          <Btn
            class="back-btn"
            round
            grow
            icon="arrow-left"
            :to="{ name: 'essaiChildFixedReports', params: {id: $route.params.id} }"
          />
          <h1 class="page-title">Création d'un rapport statistiques</h1>
        </div>
      </div>
      <div class="view-body">
        <Section class="section--border-bottom bg-gray-lightestest">
          <Container>
            <Timeline
              :index="step"
              :total="4"
              :lastStepToRight="false"
              :stepsData="stepsData"
            />
          </Container>
        </Section>

        <router-view
          v-model="submit"
          @set-data="setData"
          @set-step="setStep"
          @set-route="
            (event) => {
              this.previousRouteName = event.previousRouteName;
              this.routeParams = event.routeParams;
            }
          "
        >
        </router-view>

        <div class="view-footer">
          <div class="view-actions view-actions--left">
            <Btn text="Annuler" :to="{ name: 'essais' }" />
            <Btn
              v-if="previousRouteName"
              :to="{ name: previousRouteName, params: routeParams }"
              text="Précédent"
              icon="chevron-big-left"
            />
            <template v-if="!$route.name.includes('Variables')">
              <Btn
                @click="submit = true"
                text="Suivant"
                color="primary"
                icon="chevron-big-right"
                icon-after
              />
            </template>
            <template v-else>
              <Btn
                @click="submit = true"
                text="Enregistrer"
                color="primary"
                icon="check"
                icon-after
              />
            </template>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- Modals modalDelete-->
  <Modal
    title="Supprimer un essai&nbsp;?"
    :active="modalDelete"
    :data="essai"
    @modal-close="modalDelete = false"
  >
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment supprimer l'essai
        <b>{{ data.designation }}</b
        >&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modalDelete = false" />
      <Btn
        text="Supprimer"
        @click="deleteItem()"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import Timeline from '@/components/layout/Timeline.vue'

export default {
  name: 'EssaiAddEditView',
  components: {
    Btn,
    Section,
    Container,
    Timeline,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      routeName: null,
      previousRouteName: null,
      routeParams: {},
      method: 'post',
      submit: false,
      modalDelete: false,
      componentKey: 0,
      step: 0,
      stepsData: ['Intitulé', 'Modalités', 'Répétitions', 'Variables'],
      essai: {},
    }
  },

  emits: ['updateEssais'],

  watch: {
    previousRouteName() {
      this.$nextTick(() => {
        this.componentKey += 1
      })
    },
  },
  methods: {
    setStep(event) {
      this.step = event
    },
    setData(data) {
      this.essai = data
    },
    deleteItem() {
      this.emitter.emit('open-loader')
      this.fetchService.delete(`essai/${this.essai.id}`).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: "L'essai a été supprimée.",
          })
          this.$router.push({ name: 'essais' })
          this.emitter.emit('close-loader')
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
          this.emitter.emit('close-loader')
        },
      )
      this.modalDelete = false
    },
  },
}
</script>
